import React from 'react';
import Man1 from 'images/men/bulle1.svg';
import Man2 from 'images/men/bulle2.svg';
import Man3 from 'images/men/bulle3.svg';

export const testimonies = [
  {
    testimony:
      'Lors de ma première fois, j’ai eu mal. Et depuis, j’ai toujours eu mal. Pour moi, le sexe comportait une part de douleur qui était totalement normale ! C’est en parlant avec des amies que je me suis rendue compte que ça ne l’était pas. J’en ai parlé avec mon médecin qui n’a pas su m’aider, avant de prendre des consultations sur Mia.co. Aujourd’hui, je suis guérie et je redécouvre la sexualité sous un autre jour.',
    patient: 'Camille',
    city: '',
    age: '22 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Après avoir eu mon deuxième bébé, j’ai commencé à avoir des douleurs lors des rapports. J’ai donc fait une pause de ce côté-là parce que la souffrance ne devrait pas faire partie de la sexualité ! Après quelques consultations avec la sexologue qui me suit, nous avons identifié et réglé le problème. Un véritable soulagement et une redécouverte d’une sexualité que j’avais peur de perdre !',
    patient: 'Cindy',
    city: '',
    age: '34 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
  {
    testimony:
      'Je suis ménopausée depuis 2 ans maintenant. Je faisais de moins en moins l’amour, malgré une nouvelle relation, une nouvelle vie d’ailleurs, parce que je ressentais une gêne, voire une douleur. J’ai consulté une sexologue sur le site sur le conseil avisé de ma fille et je ne regrette pas, nous avons trouvé des solutions et je peux vivre ma nouvelle histoire d’amour sereinement.',
    patient: 'Véronique',
    city: '',
    age: '53 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Avoir des rapports sexuels a toujours été compliqué pour moi. J’ai entendu parler de vaginisme depuis peu et j’ai décidé de trouver des solutions car j’ai vu que ça pouvait se guérir. J’ai fait plusieurs séances avec une sexologue du site et nous sommes en bonne voie pour régler définitivement ce vaginisme qui me pesait depuis plusieurs années déjà.',
    patient: 'Elisa',
    city: '',
    age: '26 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      'Vaginisme : définition',
    body: (
      <div>
        <p>
          Le vaginisme est caractérisé par un rétrécissement de l’orifice vaginal, causé par des
          contractions spasmodiques involontaires des muscles releveurs de l’anus. Ces
          contractions peuvent tout autant survenir lors d’une consultation médicale, que lors
          d’un rapport sexuel avec la tentative d’introduire quelque chose dans le vagin.
        </p>
        <p>
          La simple pensée de la pénétration peut suffire pour que les muscles qui entourent le
          vagin se contractent involontairement. Et les symptômes associés au vaginisme sont plus
          ou moins prononcés selon les femmes.
        </p>
        <p>
          Dans les cas les plus graves, une femme concernée est dans l’impossibilité d’introduire
          un doigt  ou un tampon à l’intérieur de son vagin. Les examens gynécologiques sont
          également impossibles, ce qui constitue un réel problème.
        </p>
        <p>
          Lorsque les symptômes sont moins présents, l’examen gynécologique est possible. Mais les
          rapports sexuels avec pénétration du pénis ou des doigts sont impossibles. Parfois, la
          pénétration vaginale est cependant possible, mais s'accompagne de douleurs.
        </p>
        <p>
          On différencie 2 sortes de vaginisme : primaire et secondaire. Le vaginisme primaire est
          plus fréquent, et est présent dès le début de la vie sexuelle : il est impossible
          d’introduire quoi que ce soit à l’intérieur du vagin, et ce à n’importe quel moment,
          sans que la femme ne ressente des douleurs.
        </p>
        <p>
          Beaucoup de facteurs peuvent déclencher le vaginisme, comme des faits traumatisants,
          une aversion de la sexualité, une éducation sexuelle insatisfaisante ou encore des
          facteurs neurobiologiques et psycho-affectifs. Il peut aussi se manifester en cas
          d’infections génitales fréquentes et récidivantes.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quelle(s) différence(s) entre le vaginisme et les dyspareunies ?',
    body: (
      <div>
        <p>
          Les dyspareunies concernent des douleurs ressenties lors des rapports sexuels, qui
          n’incluent pas nécessairement la pénétration ; ces douleurs peuvent donc être localisées
          sur toute la vulve, et pas uniquement à l’intérieur du vagin.
        </p>
        <p>
          Les dyspareunies peuvent se manifester de plusieurs façons selon les femmes. Parfois
          décrites comme des piqûres, des brûlures, des torsions ou « des coups de couteau »,
          les douleurs peuvent être plus ou moins profondes, primaires ou secondaires.
        </p>
        <p>
          Ces douleurs profondes sont généralement liées à la présence de lésions de la paroi
          vaginale, des ligaments utéro-sacrés ou du rectum. Mais on ne retrouve pas tout le temps
          une origine « mécanique » à ces douleurs.
        </p>
        <p>
          Les muscles du plancher pelvien semblent par ailleurs impliqués dans les douleurs
          chroniques des dyspareunies.
        </p>
        <p>
          Quant aux dyspareunies superficielles, elles sont difficiles à diagnostiquées, car elles
          impliquent souvent une douleur très localisée et inexpliquée de la vulve (vulvodynie).
          La vulvodynie étant définie comme « la persistance d’une douleur vulvaire sans cause
          identifiable ».
        </p>
        <p>
          Les principales hypothèses sont des causes hormonales.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les traitements des douleurs à la pénétration ?',
    body: (
      <div>
        <p>
          Les traitements seront spécifiques selon chaque femme. Une femme atteinte de vaginisme
          n’aura pas la même prise en charge si le vaginisme est primaire ou secondaire. De même
          que l’origine des dyspareunies, ou leur localisation, va orienter les traitements.
        </p>
        <p>
          Le plus souvent, la prise en charge repose sur une thérapie psycho-sexologique, en plus
          d’un traitement pharmacologique pour soulager les douleurs ou permettre d’avoir des
          rapports sexuels si la patiente le désire.
        </p>
        <p>
          Le diagnostic médical est donc fondamental, comme l’importance d’être orientée vers des
          spécialistes des douleurs pelviennes.
        </p>
        <p>
          Ainsi, une patiente peut être prise en charge par une équipe médicale regroupant
          plusieurs spécialistes : des gynécologues, kinésithérapeutes, sages-femmes, médecins
          sexologues, psychologues, etc.
        </p>
      </div>
    ),
  },

  {
    title:
      'Comment téléconsulter une sexologue sur Mia.co ?',
    body: (
      <div>
        <p>
          Avant la téléconsultation, il est d’abord nécessaire de remplir un questionnaire
          médical précis, qui permettra au sexologue de connaître d’éventuels antécédents
          médicaux. Ensuite, la patiente peut choisir le créneau horaire qui lui convient
          le mieux, ainsi que le jour et la date.
        </p>
        <p>
          3 modes de téléconsultation s’offrent alors :
          <p className="ml-4 mt-3">
            • Par
            <b> vidéo ;</b>
            <br />
            <b>• Messages sécurisés ;</b>
            <br />
            • Ou par
            <b> téléphone.</b>
          </p>
        </p>
        <p>
          Une fois le RDV confirmé et les informations personnelles remplies, le prix de la
          téléconsultation doit être préréglé sur la plateforme.
        </p>
        <p>
          Lorsque la consultation est terminée, le dossier patient est accessible à tout moment
          dans l’espace personnel, ainsi que le compte-rendu de la consultation et les
          recommandations d’accompagnement.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les avantages de Mia.co ?',
    body: (
      <div className="ml-4">
        <p>
          • Une équipe de praticiennes (sexologues, sages-femmes, thérapeutes de couple)
          spécialistes de la sexualité féminine et spécialement formées à la pratique de la
          téléconsultation ;
        </p>
        <p>
          • Un comité scientifique composé de médecins sexologues, de professeures d’université
          en sexologie et de membres dirigeants d’associations de sexologues ;
        </p>
        <p>
          • Une consultation de 30 min à 45 euros (les prix en cabinet varient de 80 à 100 euros) ;
        </p>
        <p>
          • La livraison de produits d’accompagnement en 24 ou 48H.
        </p>
      </div>
    ),
  },
];
