import React from 'react';
import VideoSection from 'components/common/VideoSection';

const video = {
  filename: 'videos/thumbnails/consulter-douleurs-mia-co.png',
  alt: '',
  intro: '',
  video: 'https://www.youtube.com/embed/sPSwkmstajc',
};

const VideoDouleur = () => <VideoSection {...video} />;

export default VideoDouleur;
